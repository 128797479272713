<template>
  <Dialog class="p-col-6" style="text-align: center;" v-model:visible="display" @hide="close()">
    <Toast/>
    <template #header>
      <h3>{{ title }}专家</h3>
    </template>
    <div class="p-col-12">
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
          <div style="text-align: left">
            <label>姓名:</label>
            <InputText v-model="from.userName" class="p-mt-2"/>
          </div>
        </div>

        <div class="p-col-12 p-md-6">
          <div style="text-align: left">
            <label>职位/职称:</label>
            <InputText class="p-mt-2" v-model="from.position"/>
          </div>
        </div>

        <div class="p-col-12 p-md-6">
          <div style="text-align: left">
            <label>专业领域:</label>
            <InputText class="p-mt-2" v-model="from.industry"/>
          </div>
        </div>

        <div class="p-col-12 p-md-6">
          <div style="text-align: left">
            <label>获得奖项:</label>
            <InputText class="p-mt-2" v-model="from.prize"/>
          </div>
        </div>
        <div class="p-col-12">
          <div style="text-align: left">
            <label>履历简介：</label>
            <div class="p-mt-2">
              <my-editor :value="from.resume" v-on:input="setContent"></my-editor>
              <!--                            <Editor v-model="from.resume" editorStyle="height: 320px"/>-->
            </div>
          </div>
        </div>
        <div style="text-align: left" class="p-col-12">
          <label>风采展示/照片：</label>
          <div class="p-grid p-col p-mt-2">
            <FileUpload v-model="from.pic" mode="basic" name="files" url="fileUploadUrl" accept="image/*"
                        :maxFileSize="1000000" @upload="onUpload" @select="selectPic" :auto="true" chooseLabel="添加照片"/>
            <img class="p-mt-2" style="width: 100px" :src="picUrl">
            <!--                        <Button style="width: 8%" class="p-ml-2 p-button-outlined" label="浏览"/>-->
          </div>
        </div>
      </div>
    </div>
    <div class="p-grid p-col-12 p-mt-6 p-jc-around">
      <Button label="取消" @click="display=false" class="p-button-outlined"/>
      <Button label="保存" class="p-button-outlined" @click="submit"/>
    </div>
  </Dialog>
</template>

<script>
import myEditor from "@/views/backend/myEditor";

export default {
  components: {
    myEditor
  },
  name: "",
  data() {
    return {
      title: false,
      display: false,
      fileUploadUrl: this.$global_msg.frontendUrl + "/file/upload",
      from: {
        id: null,
        userName: null,
        position: null,
        prize: null,
        industry: null,
        postTime: null,
        resume: null,
        pic: null,
      },
      picUrl: null,
    }
  },
  methods: {
    init(info) {
      this.display = true
      if (info != null) {
        this.title = "编辑"
        this.from = info
      }
    },
    setContent(val){
      this.from.resume = val
    },
    close() {
      this.$emit('close', false)
    },
    selectPic(event) {
      this.picUrl = event.files[0].objectURL
    },
    onUpload(event) {
      let imgUrls = JSON.parse(event.xhr.response)
      let success = 0
      let len = 0
      for (let i in imgUrls) {
        len++
        if (imgUrls[i] !== "上传失败") {
          success++
        }
      }
      if (success === len) {
        this.from.pic = event.xhr.response
        this.$toast.add({severity: 'info', summary: '成功', detail: '文件上传成功', life: 3000});
      } else {
        this.$toast.add({severity: 'error', summary: '失败', detail: (len - success) + '个文件上传失败', life: 3000});
      }
    },
    submit() {
      this.$http.post('/expert/ExtendExpert', this.from).then(() => {
        this.display = false
        this.$toast.add({severity: 'info', summary: '成功', detail: '操作成功', life: 3000})
      })
    },
  }
}
</script>

<style scoped>

</style>