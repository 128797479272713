<template>
    <diV class="page p-grid">
        <div class="p-col-12">
            <Breadcrumb :model="items"/>
        </div>
        <div class="p-d-flex p-col">
            <InputText v-model="userName" placeholder="专家姓名搜索"/>
            <Calendar style="width: 22.5%" class="p-ml-3" v-model="time" placeholder="入驻时间"/>
            <Dropdown class="p-ml-3" v-model="industry" :options="industries" optionLabel="name" option-value="code" placeholder="所属行业"/>
            <Button @click="extendExpertTermQuery()" icon="pi pi-search" label="查询" class="p-button-info p-ml-2"/>
        </div>
        <div class="p-p-2">
            <DataTable ref="dt" :value="assist" v-model:selection="selectedProducts" dataKey="id"
                       :paginator="true" :rows="10" :filters="filters"
                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                       currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计{totalRecords}记录">
                <Column selectionMode="multiple" headerStyle="width: 3rem" :exportable="false"></Column>
                <Column field="id" header="序号" :sortable="true"></Column>
                <Column field="userName" header="专家" :sortable="false">
                </Column>
                <Column field="industry" header="行业" :sortable="false">
                </Column>
                <Column field="postTime" header="入驻时间" :sortable="true">
                </Column>
                <Column  field="operation" header="操作" :exportable="false">
                    <template #body="slotProps">
                        <Button @click="update(slotProps.data)">编辑</Button>
<!--                        <Button  class="p-button-warning p-ml-1" @click="confirmDeleteProduct(slotProps.data)" >置顶</Button>-->
                    </template>
                </Column>
            </DataTable>
        </div>
        <ExtendExpert v-if="display" ref="update" v-on:close="diaLogClose"></ExtendExpert>
    </diV>
    <Toast/>
</template>

<script>
    import ExtendExpert from "@/views/platform/thinkTankManagement/ExtendExpert";
    export default {
        mounted() {
            this.showExpert()
        },
        components:{ExtendExpert},
        name: "",
        data() {
            return {
                userName: null,
                time: null,
                industry: null,
                date: null,
                selectedIndustry: null,
                items: [
                    {label: '智库推送', to: '/AssistExtension'},
                    {label: '协助推广', to: '/AssistExtension'}
                ],
                industries: [
                    {name: '旅游服务行业', code: '旅游服务行业'},
                    {name: '酒店服务行业', code: '酒店服务行业'},
                    {name: '门店服务行业', code: '门店服务行业'},
                    {name: '饭店服务行业', code: '饭店服务行业'},
                    {name: '交通服务行业', code: '交通服务行业'}
                ],
                filters: {},
                selectedProducts: null,
                display:false,
                assist: [],
            }
        },
        methods: {
            goto() {
                this.$router.push('/informationEditor')
            },
            showExpert() {
                this.$http.get('/expert/showExport').then((res) => {
                    this.assist = res.data
                })
            },
            update(info){
                this.display=true
                this.$nextTick(() => {
                    this.$refs.update.init(info)
                })
            },
            diaLogClose(e){
                this.display=e
            },
            extendExpertTermQuery() {
                let time = new Date(this.time == ""?1970-1-1:this.time)
                time = time.getFullYear() + '-' + (time.getMonth() + 1) + '-' + time.getDate();
                this.$http.get('/expert/expertTermQuery', {params: {'userName': this.userName, 'postTime': time, 'industry': this.industry}}).then((res) => {
                    this.assist = res.data
                    this.$toast.add({severity:'success', summary: '成功', detail: '查询成功', life: 3000});
                })
            }
        }
    }
</script>

<style scoped>

</style>